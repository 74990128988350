import { capitalize, groupBy, uniq } from 'lodash-es';

import { toTitleCase } from './utils.ts';

/**
 * Format comments, returns `''` on no undefined comments
 */
export const getPrettyComments = (stockItem: Stock) => {
  if (stockItem.comments) {
    const punctuationRegex = /(\.|!|\?)/;
    let output = stockItem.comments;

    // Remove all-caps
    if (stockItem.comments.toUpperCase() === stockItem.comments) {
      output = output
        .split(punctuationRegex)
        .map((c) => capitalize(c))
        .join('');
    }

    // Remove trailing newlines
    output = output.replace(/\n+$/, '');

    // Add final punctuation if nothing exists
    if (!output.slice(-1).match(punctuationRegex)) {
      output += '.';
    }

    return output
      .replace('. .', '.') // Remove repeated full-stops
      .replace(/(\w)\s+\./, '$1.') // Remove spaces at the end of a sentence
      .replace(/([^\n])\n([^\n])/, '$1\n\n$2') // Double up all single newlines
      .replace('**', '\n\n**') // Move double-asterisks to new line
      .replace(/(\w)\*(\w)/, '$1* $2') // Space single-asterisks correctly
      .replace(/,+[.]?$/, '.'); // Replace ending comma with a full stop
  }

  return '';
};

/**
 * Formats milage to `{mileage}km`, returns `N/A` on missing mileage
 */
export const getPrettyMileage = (stockItem: Stock) => {
  if (typeof stockItem.mileage !== 'undefined' && stockItem.mileage !== null) {
    return stockItem.mileage.toLocaleString().split('.')[0] + 'km';
  }

  return 'N/A';
};

export const getMedTitle = (stockItem: Stock) => {
  return [stockItem.year, stockItem.make, stockItem.model, stockItem.badge].filter((v) => v).join(' ');
};

export const getEngineDetailsString = (stockItem: Stock) => {
  const parts = [];
  if (stockItem.cylinders) {
    parts.push(`${stockItem.cylinders} cylinder`);
  }

  if (stockItem.engineCapacity) {
    parts.push(`${stockItem.engineCapacity}L`);
  }

  return parts.join(' ');
};

export const getSimpleTransmission = (stockItem: Stock) => {
  return stockItem.transmission?.toLowerCase()?.includes('manual') ? 'Manual' : 'Automatic';
};

export const getShortTransmission = (stockItem: Stock) => {
  return stockItem.transmission?.toLowerCase().includes('manual') ? 'Manual' : 'Auto';
};

export const getFuelTypeFeature = (stockItem: Stock) => {
  return isElectricVehicle(stockItem) ? 'Plug-in electric' : stockItem.fuelType?.toLowerCase();
};

export const getFuelType = (stockItem: Stock) => {
  return isElectricVehicle(stockItem) ? 'electric' : 'petrol';
};

export const getFuelEfficiency = (stockItem: Stock) => {
  return isElectricVehicle(stockItem) ? 'Per charge' : 'Economy';
};

export const getFuelEfficiencyIcon = (stockItem: Stock) => {
  return isElectricVehicle(stockItem) ? 'recharging' : 'droplet';
};

export const getFuelEfficiencyValue = (stockItem: Stock) => {
  return isElectricVehicle(stockItem)
    ? `${stockItem.fuelEfficiency}kWh / 100km`
    : `${stockItem.fuelEfficiency}L / 100km`;
};

export const getDriveType = (stockItem: Stock) => {
  const inputDriveType = stockItem.driveType;
  return inputDriveType || null;
};

export const getMakeModel = (stockItem: Stock) => {
  return `${toTitleCase(stockItem.make)} ${toTitleCase(stockItem.model)}`;
};

export const getGovernmentChargesString = (stockItem: Stock) => {
  if (!stockItem.price) return null;
  return !!stockItem.egcPrice ? 'e.g.c' : 'Drive away';
};

export const getRawFuelType = (stockItem: Stock) => {
  return `${stockItem.fuelType}`;
};

export const getFeatureGroups = (stockItem: Stock) => {
  return uniq(stockItem.features?.filter((f): f is Feature => !!f).map((f) => f.group));
};

export const getFeaturesFor = (stockItem: Stock, featureGroup: string) => {
  const features = groupBy(stockItem.features?.filter((f): f is Feature => f?.group === featureGroup), (f) => f?.name);
  return Object.keys(features).map((name) => ({ name, features: features[name].map((f) => f?.value) }));
};

export const hasFeature = (stockItem: Stock, featureGroup: string, featureName: string, value: string) => {
  const featureType = getFeaturesFor(stockItem, featureGroup).find((f) => f.name === featureName);
  if (featureType) {
    return featureType.features.filter((f) => f?.includes(value)).length > 0;
  }
  return false;
};

export const extractStockPhotos = (stockItem: Stock): string[] => {
  if (stockItem.photos) {
    return stockItem.photos.filter((f): f is string => !!f);
  }
  return [];
};

const isElectricVehicle = (stockItem: Stock) => {
  return !!stockItem.fuelType?.toLowerCase().includes('electric');
};
