import React from 'react';

import styles from './styles.module.scss';

interface VCDealerCommentsProps {
  comments?: string | false;
}

const VCDealerComments = ({ comments }: VCDealerCommentsProps) => {
  return (
    <div className={styles.vcDealerComments}>
      <p>{comments}</p>
    </div>
  );
};

export default VCDealerComments;
