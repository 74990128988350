import React, { useMemo, useRef, useState } from 'react';
import {
  FloatingPortal,
  arrow,
  autoUpdate,
  detectOverflow,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';

import useIsMobile from '@/common/hooks/useIsMobile.ts';

import IconX from '@/common/icons/tabler/x.svg';

import styles from './styles.module.scss';

// ------------------------------------------------------------------------------------
// TOOLTIP BOUNDARY
// ------------------------------------------------------------------------------------

interface TooltipBoundaryOptions {
  boundaryRef: React.MutableRefObject<HTMLDivElement | null>;
  /** ignore boundary on mobile; defaults to false */
  ignoreOnMobile?: boolean;
  /** margin tooltip should overhang boundary; defaults to 0 */
  overhang?: number;
}

function useInternalTooltipBoundary({ boundaryRef, ignoreOnMobile = false, overhang = 0 }: TooltipBoundaryOptions) {
  return useMemo(
    () => ({ boundaryRef, ignoreOnMobile, overhang: overhang * 2 }),
    [boundaryRef, ignoreOnMobile, overhang],
  );
}

type InternalTooltipBoundaryType = ReturnType<typeof useInternalTooltipBoundary> | null;
const TooltipBoundaryContext = React.createContext<InternalTooltipBoundaryType>(null);

const useTooltipBoundaryContext = () => {
  const context = React.useContext(TooltipBoundaryContext);
  return context;
};

/**
 * Provides a boundary for any child `<Tooltip/>` components to exist within. If there are multiple Boundaries, `<Tooltol/>` will use closest parent.
 */
export const TooltipBoundary = ({
  children,
  ignoreOnMobile,
  overhang,
  ...htmlProps
}: { children: React.ReactNode } & Omit<TooltipBoundaryOptions, 'boundaryRef'> &
  React.ComponentPropsWithoutRef<'div'>) => {
  const boundaryRef = useRef<HTMLDivElement | null>(null);
  const boundary = useInternalTooltipBoundary({ boundaryRef, ignoreOnMobile, overhang });

  return (
    <TooltipBoundaryContext.Provider value={boundary}>
      <div {...htmlProps} ref={boundaryRef}>
        {children}
      </div>
    </TooltipBoundaryContext.Provider>
  );
};

// ------------------------------------------------------------------------------------
// TOOLTIP
// ------------------------------------------------------------------------------------

interface TooltipOptions {
  /** Open tooltip on initial render; default: `false` */
  initialOpen?: boolean;
  /** Set placement in relation to TooltipTrigger; default: `'top'` */
  placement?: Placement;
  /** If provided, will make component controlled, use onOpenChange() to get latest state updates from component */
  open?: boolean;
  /** If provided, will make component controlled, open should be set here */
  onOpenChange?: (open: boolean) => void;
  /** If true, will look for closest `<TooltipBoundary/>` parent to set as boundary; default: `true`  */
  useTooltipBoundary?: boolean;
  /** Offset tooltip from trigger; default: 6 */
  offset?: number;
  /** Width in px to set tooltip; will be ignored on mobile. Wont overflow `<TooltipBoundary/>`; default: `395` */
  width?: number;
}

/**
 * Internal hook to get memoized TooltipOptions from context
 */
function useInternalTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  useTooltipBoundary = true,
  offset: tooltipOffset = 6,
  width = 395,
}: TooltipOptions = {}) {
  const [isMobile] = useIsMobile(768);
  const tooltipBoundary = useTooltipBoundaryContext();
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      // Custom middleware to pass isMobile
      {
        name: 'responsive',
        async fn() {
          return {
            data: {
              isMobile: window.innerWidth <= 768,
            },
          };
        },
      },
      // Custom middleware to set boundary data if available
      {
        name: 'tooltipBoundary',
        async fn({ middlewareData }) {
          if (
            useTooltipBoundary &&
            tooltipBoundary?.boundaryRef?.current &&
            !(tooltipBoundary?.ignoreOnMobile && middlewareData.responsive.isMobile)
          ) {
            return {
              data: {
                boundary: tooltipBoundary.boundaryRef.current,
                overhang: tooltipBoundary.overhang,
              },
            };
          }
          return {};
        },
      },
      offset({
        mainAxis: tooltipOffset + 6, // offset + arrow staticSide
      }),
      flip(({ middlewareData }) => {
        const headerPadding = middlewareData.responsive.headerPadding as number;
        return {
          crossAxis: placement.includes('-'),
          fallbackAxisSideDirection: 'none',
          mainAxis: true,
          padding: {
            top: headerPadding + tooltipOffset,
            bottom: tooltipOffset,
          },
        };
      }),
      // handles setting the mainAxis with boundary (shifting left / right to fit boundary)
      shift(({ middlewareData }) => {
        const bounding = (
          middlewareData.tooltipBoundary?.boundary as HTMLDivElement | undefined
        )?.getBoundingClientRect();
        const isMobileIgnoringBoundary =
          middlewareData.responsive.isMobile && Object.keys(middlewareData.tooltipBoundary).length === 0;

        return {
          boundary: bounding
            ? {
                x: bounding.x - middlewareData.tooltipBoundary.overhang / 2,
                y: bounding.y,
                width: bounding.width,
                height: bounding.height,
              }
            : undefined,
          mainAxis: true,
          padding: {
            right: isMobileIgnoringBoundary ? 2 : 0,
            left: isMobileIgnoringBoundary ? 2 : 0,
          },
        };
      }),
      // Custom middleware set size based on boundary
      {
        name: 'boundarySize',
        async fn(state) {
          // if on mobile and boundary ignoreOnMobile = true; set width to 100%
          const { middlewareData } = state;
          if (middlewareData.responsive.isMobile && Object.keys(middlewareData.tooltipBoundary).length === 0) {
            Object.assign(state.elements.floating.style, {
              maxWidth: 'calc(100% - 4px)',
            });
            return {};
          }

          let setWidth = width;
          if (state.middlewareData.tooltipBoundary?.boundary) {
            const overflow = await detectOverflow(state, {
              boundary: state.middlewareData.tooltipBoundary.boundary,
            });

            const overflowWidth =
              -overflow.left -
              overflow.right +
              state.rects.floating.width +
              state.middlewareData.tooltipBoundary.overhang;

            // if width is > overflow (max width w/ boundary) or isMobile set width
            if (setWidth > overflowWidth || isMobile) setWidth = overflowWidth;
          }

          Object.assign(state.elements.floating.style, {
            maxWidth: `${Math.min(setWidth, window.innerWidth)}px`,
          });

          return {};
        },
      },
      arrow({
        element: arrowRef,
      }),
    ],
  });

  // Get static side for arrow placement
  const arrowPlacement = useMemo(
    () =>
      ({
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      })[data.placement.split('-')[0]] ?? 'top',
    [data.placement],
  );

  const context = data.context;

  // Event listeners to change the open state
  const hover = useHover(context, { move: false, mouseOnly: true, enabled: !isMobile });
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    enabled: !isMobile,
  });
  const click = useClick(context, {
    enabled: isMobile,
  });
  // // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const interactions = useInteractions([hover, focus, dismiss, click, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      setArrowRef,
      arrowPlacement,
    }),
    [open, setOpen, interactions, data, arrowPlacement],
  );
}

type TooltipContextType = ReturnType<typeof useInternalTooltip> | null;
const TooltipContext = React.createContext<TooltipContextType>(null);

/**
 * Internal hook to get Tooltip Context, throws error if user attempts to use child component outside of <Tooltip/>
 */
const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  const tooltip = useInternalTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

/**
 * Trigger for Tooltip component. By default will wrap text into a `button`.
 * Also attaches `data-state` (`'open' | 'closed'`) for custom styling
 * @param asChild Allows any passed element as the anchor (set if using custom component)
 */
export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
  function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows any passed any element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      );
    }

    return (
      <button
        type="button"
        ref={ref}
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  },
);

/**
 * Content to display within tooltip, wraps in div
 */
export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipContent(
  { children, ...props },
  propRef,
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context.context);

  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...transitionStyles,
        }}
        className={styles.tooltip}
        data-placement={context.placement}
        data-testid="tooltip-content"
        {...context.getFloatingProps(props)}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.content} role="tooltip">
            {children}
          </div>
          {/* not visible; exists just for layout sizing */}
          <div style={{ visibility: 'hidden' }} data-show-only="mobile">
            <IconX height={16} width={16} />
          </div>
          <button
            className={styles.close}
            type="button"
            onClick={() => context.setOpen(false)}
            data-testid="tooltip-close"
            data-show-only="mobile"
          >
            <IconX height={16} width={16} />
          </button>
        </div>
        <div
          ref={context.setArrowRef}
          style={{
            position: 'absolute',
            top: context.middlewareData.arrow?.y,
            left: context.middlewareData.arrow?.x ? context.middlewareData.arrow.x : undefined,
            [context.arrowPlacement]: '-6px',
          }}
          className={styles.arrow}
        />
      </div>
    </FloatingPortal>
  );
});
