import React from 'react';

import IconMapPin from '@/common/icons/tabler/map-pin.svg';

import styles from './styles.module.scss';

interface VCLocationProps {
  suburb: string;
}
const VCLocation = ({ suburb }: VCLocationProps) => {
  return (
    <div className={styles.vcLocation}>
      {!!suburb && (
        <div className={styles.in}>
          <IconMapPin width={16} height={16} />
          In {suburb}
        </div>
      )}
    </div>
  );
};
export default VCLocation;
