import { orderBy } from 'lodash-es';
import { Client } from 'urql';

import stockQuery from './query.graphql';

export default class StockSearch {
  static DEFAULTS = {
    size: 10,
    sortBy: 'datePublished',
    sortByOrder: 'DESC' as SortByOrder,
  };

  /**
   * Stock Search Query with NextJS revalidation cache
   *
   * See: https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#fetching-data-on-the-server-with-fetch
   *
   * @param client
   * @param filters
   * @returns stock search GQL operation result
   */
  static async query(client: Client, filters: SearchDirectory.Filters) {
    const tags = ['stockSearch'];
    if (filters.sortBy === 'datePublished' && filters.sortByOrder === 'DESC') {
      tags.push('justAddedStock');
    }

    const result = await client
      .query<StockSearchQuery, StockSearchQueryVariables>(stockQuery, filters, {
        fetchOptions: { next: { revalidate: 900 /* 15min */, tags } },
      })
      .toPromise();

    if (result.error) {
      console.debug(`StockSearchQuery: /${StockSearch.buildURL(filters)} -> ${result.error.message}.`);
    } else {
      console.debug(
        `StockSearchQuery: /${StockSearch.buildURL(filters)} -> ${result.data?.searchStock.total} results.`,
      );
    }

    return result;
  }

  /**
   * Server side preloading and caching of stock search query.
   * Only preload commonly used filters.
   *
   * See: https://nextjs.org/docs/app/building-your-application/data-fetching/fetching#using-react-cache-and-server-only-with-the-preload-pattern
   *
   * @param client
   * @param filters
   * @param filterOptions
   */
  static async preload(client: Client, filters: SearchDirectory.Filters, filterOptions: SearchDirectory.FilterOptions) {
    const makes = filterOptions?.makes || [];
    const listingTypes = StockSearch.parseListingTypes(
      filterOptions?.listingTypes?.map((listingType) => listingType.name) || [],
    );
    const fuelTypes = filterOptions.fuelTypes?.map((fuelType) => fuelType.name) || [];
    void Promise.all([
      // Preload popular makes
      ...StockSearch.getPopularMakes(makes).map((make) =>
        StockSearch.query(client, {
          ...filters,
          vehicleSearchQueries: StockSearch.nextVehicleSearchQuery(
            { make: make.name },
            filters.vehicleSearchQueries || [],
          ),
        }),
      ),
      // Preload all listing types
      ...listingTypes.map((listingType) =>
        StockSearch.query(client, {
          ...filters,
          listingTypes: [...(filters.listingTypes || []), listingType],
        }),
      ),
      // Preload Electric Filter
      ...(fuelTypes.includes('Electric')
        ? [StockSearch.query(client, { ...filters, fuelTypes: [...(filters.fuelTypes || []), 'Electric'] })]
        : []),
    ]);
  }

  static buildURL(filters: Partial<SearchDirectory.Filters>) {
    const params = new URLSearchParams();
    const makesAdded = new Set();
    for (const name of Object.keys(filters) as Array<keyof SearchDirectory.Filters>) {
      switch (name) {
        case 'vehicleSearchQueries':
          filters[name]?.forEach((query) => {
            if (query.make) {
              // Check if make is already added
              if (!makesAdded.has(query.make)) {
                params.append('vehicleSearchQuery', `make:${query.make}`);
                makesAdded.add(query.make);
              }

              if (query.model) {
                const queryString = query.badge
                  ? `make:${query.make}|model:${query.model}|badge:${query.badge}`
                  : `make:${query.make}|model:${query.model}`;

                params.append('vehicleSearchQuery', queryString);
              }
            }
          });
          break;
        case 'dealershipLocationQueries':
          filters[name]?.forEach((query) => {
            if (query.state && query.city && query.suburb) {
              params.append(
                'dealershipLocationQuery',
                `state:${query.state}|city:${query.city}|suburb:${query.suburb}`,
              );
            } else if (query.state && query.city) {
              params.append('dealershipLocationQuery', `state:${query.state}|city:${query.city}`);
            } else if (query.state) {
              params.append('dealershipLocationQuery', `state:${query.state}`);
            }
          });
          break;
        case 'dealershipGeo':
          if (!filters.dealershipGeo) break;
          params.set('lat', filters.dealershipGeo.latitude.toString());
          params.set('lng', filters.dealershipGeo.longitude.toString());
          if (filters.dealershipGeo.radius) {
            params.set('radius', filters.dealershipGeo.radius.toString());
          }
          if (filters.dealershipGeo.streetAddress) {
            params.set('address', filters.dealershipGeo.streetAddress);
          }
          break;
        case 'seatsRange':
        case 'yearRange':
        case 'weeklyPriceRange':
          const { min, max } = filters[name] || {};
          const queryName = {
            seatsRange: 'Seats',
            yearRange: 'Year',
            weeklyPriceRange: 'WeeklyPrice',
          }[name];
          if (typeof min === 'number') {
            params.set(`min${queryName}`, min.toString());
          }
          if (typeof max === 'number') {
            params.set(`max${queryName}`, max.toString());
          }
          break;
        case 'page':
          const page = filters[name];
          if (page && page !== 1) {
            params.set('page', String(page));
          }
          break;
        default:
          const value = filters[name];
          if (Array.isArray(value)) {
            !!value.length && params.set(name, value.join(','));
            break;
          }

          if (value === undefined) {
            break;
          }

          if (typeof value === 'string' && !value) {
            break;
          }

          if (
            name in StockSearch.DEFAULTS &&
            value === StockSearch.DEFAULTS[name as keyof typeof StockSearch.DEFAULTS]
          ) {
            break;
          }
          params.set(name, String(value));

          break;
      }
    }

    return !!params.toString()
      ? `cars?${params.toString().replaceAll('%2C', ',').replaceAll('%3A', ':').replaceAll('%7C', '|')}`
      : `cars`;
  }

  static getVehicleUrl(stock: Stock) {
    return `/cars/view/${stock.dealerId}/${stock.guid}`;
  }

  static nextVehicleSearchQuery(
    { make, model, badge }: SearchDirectory.VehicleSearchQuery,
    vehicleSearchQueries: SearchDirectory.VehicleSearchQueries,
  ): SearchDirectory.VehicleSearchQueries {
    const updatedQueries = [...vehicleSearchQueries];

    // Check if a query exists
    const queryExists = (checkMake?: string, checkModel?: string, checkBadge?: string) => {
      return updatedQueries.some((query) => {
        const makeMatches = query.make === checkMake || !checkMake;
        const modelMatches = query.model === checkModel || !checkModel;
        const badgeMatches = query.badge === checkBadge || !checkBadge;

        return makeMatches && modelMatches && badgeMatches;
      });
    };

    // Make/Model/Badge filter
    if (badge && model && make) {
      if (queryExists(make, model, badge)) {
        // Remove existing query
        return updatedQueries.filter(
          (query) => !(query.make === make && query.model === model && query.badge === badge),
        );
      }
      // Add new query
      return [...updatedQueries, { make, model, badge }];
    }

    // Make/Model filter
    if (make && model) {
      if (queryExists(make, model)) {
        // Remove existing query for model
        return updatedQueries.filter((query) => !(query.make === make && query.model === model));
      }
      // Add new query
      return [...updatedQueries, { make, model }];
    }

    // Make filter
    if (make) {
      if (queryExists(make)) {
        // Remove existing make query and its model & badge
        return updatedQueries.filter((query) => query.make !== make);
      }
      // Add new make
      return [...updatedQueries, { make }];
    }

    return updatedQueries;
  }

  static nextDealershipLocationQuery(
    { state, city, suburb }: SearchDirectory.DealershipLocationQuery,
    locationQueries: SearchDirectory.DealershipLocationQueries,
  ): SearchDirectory.DealershipLocationQueries {
    const updatedLocationQueries = [...locationQueries];

    // Check if a query exists
    const queryExists = (checkState?: string, checkCity?: string, checkSuburb?: string) => {
      return updatedLocationQueries.some((query) => {
        const stateMatches = query.state === checkState || !checkState;
        const cityMatches = query.city === checkCity || !checkCity;
        const suburbMatches = query.suburb === checkSuburb || !checkSuburb;

        return stateMatches && cityMatches && suburbMatches;
      });
    };

    // State/City/Suburb filter
    if (suburb && city && state) {
      if (queryExists(state, city, suburb)) {
        // Remove existing query
        return updatedLocationQueries.filter(
          (query) => !(query.state === state && query.city === city && query.suburb === suburb),
        );
      }
      // Add new query
      return [...updatedLocationQueries, { state, city, suburb }];
    }

    // State/City filter
    if (state && city) {
      if (queryExists(state, city)) {
        // Remove exisiting query for city
        return updatedLocationQueries.filter((query) => !(query.state === state && query.city === city));
      }
      // Add new query
      return [...updatedLocationQueries, { state, city }];
    }

    // State filter
    if (state) {
      if (queryExists(state)) {
        // Remove exisiting state query and its city & suburb
        return updatedLocationQueries.filter((query) => query.state !== state);
      }
      // Add new state
      return [...updatedLocationQueries, { state }];
    }

    return updatedLocationQueries;
  }

  static getFilterActiveCount(filters: SearchDirectory.Filters) {
    const filterKeys = Object.keys(filters) as Array<keyof SearchDirectory.Filters>;
    return filterKeys.reduce<number>((total, key) => {
      switch (key) {
        case 'vehicleSearchQueries':
        case 'bodyTypes':
        case 'fuelTypes':
        case 'listingTypes':
        case 'driveTypes':
        case 'bodyTypes':
        case 'transmissions':
        case 'dealershipLocationQueries':
        case 'colours':
        case 'features':
          const values = filters[key] || [];
          return total + values.length;
        case 'seatsRange':
        case 'weeklyPriceRange':
        case 'yearRange':
        case 'search':
          return total + 1;
      }
      return total;
    }, 0);
  }

  static parseListingTypes(listingTypes: Array<string>): Array<ListingType> {
    return listingTypes
      .map(StockSearch.parseListingType)
      .filter((listingType): listingType is ListingType => !!listingType);
  }

  static parseListingType(maybeListingType: string): ListingType | undefined {
    if (!maybeListingType) return undefined;
    return (['NEW', 'USED', 'DEMO'].includes(maybeListingType) ? maybeListingType : undefined) as
      | ListingType
      | undefined;
  }

  static parseSortByOrder(maybeSortByOrder: string | undefined): SortByOrder | undefined {
    if (!maybeSortByOrder) return undefined;
    return (['ASC', 'DESC'].includes(maybeSortByOrder) ? maybeSortByOrder : undefined) as SortByOrder | undefined;
  }

  static parseVehicleSearchQueriesParam(
    param: string | Array<string> | undefined,
  ): SearchDirectory.VehicleSearchQueries {
    if (!param) {
      return [];
    }
    const params = Array.isArray(param) ? param : [param];
    return params
      .map(StockSearch.parseVehicleSearchQueryParam)
      .filter((query): query is SearchDirectory.VehicleSearchQuery => !!query);
  }

  // http://localhost/cars?vehicleSearchQueries=make:Toyota|model:Camry,vehicleSearchQueries=make:Toyota
  static parseVehicleSearchQueryParam(param: string): SearchDirectory.VehicleSearchQuery | null {
    const value: Partial<SearchDirectory.VehicleSearchQuery> = decodeURIComponent(param)
      .split('|')
      .map((filter) => filter.split(':'))
      .reduce((acc, [key, value]) => {
        switch (key) {
          case 'model':
          case 'make':
          case 'badge':
            return { ...acc, [key]: value };
          default:
            return acc;
        }
      }, {});

    if (value.make) {
      return { ...value, make: value.make };
    }

    return null;
  }

  static parseDealershipLocationQueriesParam(
    param: string | Array<string> | undefined,
  ): SearchDirectory.DealershipLocationQueries {
    if (!param) {
      return [];
    }
    const params = Array.isArray(param) ? param : [param];
    return params
      .map(StockSearch.parseDealershipLocationQueryParam)
      .filter((query): query is SearchDirectory.DealershipLocationQuery => !!query);
  }

  // http://localhost/cars?dealershipLocations=state:WA|city:Perth,dealershipLocations=state:VIC
  static parseDealershipLocationQueryParam(param: string): SearchDirectory.DealershipLocationQuery | null {
    const value: Partial<SearchDirectory.DealershipLocationQuery> = decodeURIComponent(param)
      .split('|')
      .map((filter) => filter.split(':'))
      .reduce((acc, [key, value]) => {
        switch (key) {
          case 'state':
          case 'city':
          case 'suburb':
            return { ...acc, [key]: value.toLowerCase() };
          default:
            return acc;
        }
      }, {});

    if (value.state) {
      return { ...value, state: value.state };
    }

    return null;
  }

  static getPopularMakes(makes: Array<SearchFilterOptionMake>) {
    return orderBy(makes, [(filter) => filter.total], ['desc']).slice(0, 10);
  }
}
