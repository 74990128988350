import 'keen-slider/keen-slider.min.css';

import React, { useMemo } from 'react';
import cc from 'classcat';
import { KeenSliderHooks, KeenSliderInstance } from 'keen-slider';

import styles from './styles.module.scss';

interface Props {
  currentSlide: number;
  instanceRef: React.MutableRefObject<KeenSliderInstance<object, object, KeenSliderHooks> | null>;
  visibleAfterActive?: number;
  activeStyle?: 'active-action-default';
  dotStyle?: 'dot-fg-subtle';
}

const CarouselDots = ({ currentSlide, instanceRef, visibleAfterActive = 3, activeStyle, dotStyle }: Props) => {
  const slideLength = useMemo(() => instanceRef?.current?.track.details.slides.length || 0, [instanceRef]);

  return (
    <>
      {[...new Array(slideLength)]?.map((_elem, idx) => {
        return (
          <Dot
            key={`slide-${idx}`}
            ariaLabel={`go to slide ${idx + 1}`}
            onClick={(e) => {
              e.stopPropagation();
              instanceRef.current?.moveToIdx(idx);
            }}
            active={currentSlide === idx}
            medium={
              (slideLength !== currentSlide + 1 &&
                currentSlide !== 0 &&
                (idx === currentSlide - 2 || idx === currentSlide + 2)) ||
              (slideLength === currentSlide + 1 && idx === slideLength - 4) ||
              (currentSlide === 0 && idx === 3) ||
              (slideLength <= 3 && visibleAfterActive === 2 && (idx === currentSlide - 2 || idx === currentSlide + 2))
            }
            small={
              (slideLength !== currentSlide + 1 &&
                currentSlide !== 0 &&
                (idx <= currentSlide - 3 || (idx > currentSlide + 2 && idx <= currentSlide + visibleAfterActive))) ||
              (slideLength === currentSlide + 1 && idx <= slideLength - 5) ||
              (currentSlide === 0 && idx >= 4)
            }
            visible={idx >= currentSlide - visibleAfterActive && idx <= currentSlide + visibleAfterActive}
            activeStyle={activeStyle}
            dotStyle={dotStyle}
          />
        );
      })}
    </>
  );
};

const Dot = ({
  onClick,
  active,
  medium,
  small,
  visible,
  activeStyle,
  dotStyle,
  ariaLabel,
}: {
  onClick?: (e: any) => void;
  active: boolean;
  medium?: boolean;
  small?: boolean;
  visible?: boolean;
  activeStyle?: 'active-action-default';
  dotStyle?: 'dot-fg-subtle';
  ariaLabel: string;
}) => {
  let dotClassNames = cc({
    [styles.dot]: true,
    [styles.visible]: visible,
    [styles.active]: active,
    [styles.medium]: medium,
    [styles.small]: small,
  });

  if (!!activeStyle && !!styles[activeStyle]) {
    dotClassNames = cc([dotClassNames, styles[activeStyle]]);
  }

  if (!!dotStyle && !!styles[dotStyle]) {
    dotClassNames = cc([dotClassNames, styles[dotStyle]]);
  }

  const wrapperClassNames = cc({
    [styles.dotWrapper]: true,
    [styles.visible]: visible,
  });

  return (
    <button type="button" onClick={onClick} className={wrapperClassNames} aria-label={ariaLabel}>
      <div className={dotClassNames} />
    </button>
  );
};

export default CarouselDots;
