import 'keen-slider/keen-slider.min.css';

import React, { useCallback, useState } from 'react';
import cc from 'classcat';
import { useKeenSlider } from 'keen-slider/react.es.js';
import Image from 'next/image';

import Button from '@/common/components/Button/index.tsx';
import CarouselDots from '@/common/components/CarouselDots/index.tsx';
import { replaceStringByIndex } from '@/common/lib/utils.ts';

import IconChevronLeft from '@/common/icons/tabler/chevron-left.svg';
import placeholderImage from '@/common/images/placeholder.jpg';

import styles from './styles.module.scss';

interface VehicleCarouselProps {
  images: string[];
  alt: string;
  onViewFullDetails: () => void;
  showDesktopNavigation: boolean;
  /** will be called once slider has loaded */
  onLoaded?: () => void;
  /** called when carousel is clicked, and disableNavigation is true */
  onNavigate?: () => void;
  //defaults
  /** disabled click navigation & hide CTA; default false */
  disableNavigation?: boolean;
  /** preload first image; default false */
  preload?: boolean;
  /** show only the first image and remove pagination; default false */
  showOnlyFirstImage?: boolean;
}

const VCCarousel = ({
  images,
  alt,
  onViewFullDetails,
  showDesktopNavigation,
  onLoaded,
  onNavigate,
  disableNavigation = false,
  preload = false,
  showOnlyFirstImage = false,
}: VehicleCarouselProps) => {
  const [slideImages, setSlideImages] = useState(images.length ? images : [placeholderImage.src]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
      onLoaded && onLoaded();
    },
    rubberband: false,
  });

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (disableNavigation) return;
      onNavigate?.();
    },
    [disableNavigation, onNavigate],
  );

  const setPlaceholderImage = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>, idx: number) => {
      const newSlideImages = replaceStringByIndex(slideImages, placeholderImage.src, idx);
      setSlideImages(newSlideImages);
      e.preventDefault();
    },
    [slideImages],
  );

  return (
    <>
      <div
        role="button"
        className={styles.vehicleCarousel}
        onClick={handleOnClick}
        onKeyDown={(e) => e.key === 'enter' && handleOnClick(e)}
        data-navigation-disabled={disableNavigation}
        tabIndex={-1}
      >
        {showOnlyFirstImage && !!slideImages.length && (
          <div className="keen-slider">
            <div className="keen-slider__slide">
              <Image
                priority
                src={slideImages[0]}
                alt={`${alt} image 1`}
                fill
                sizes="(max-width: 695px) 80vw, (max-width: 1024px) 45vw, (max-width: 1287px) 50vw, (max-width: 1820px) 20vw, (max-width: 3440px) 17.5vw, 15vw"
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, 0)}
              />
            </div>
          </div>
        )}
        {!showOnlyFirstImage && (
          <div ref={sliderRef} className="keen-slider">
            {slideImages.map((f, i) => (
              <div key={f} className="keen-slider__slide">
                <Image
                  priority={i === 0 && preload}
                  src={f}
                  alt={`${alt} image ${i + 1}`}
                  fill
                  sizes="(max-width: 695px) 80vw, (max-width: 1024px) 45vw, (max-width: 1287px) 50vw, (max-width: 1820px) 20vw, (max-width: 3440px) 17.5vw, 15vw"
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, i)}
                />
              </div>
            ))}
            {/* Display final image but with cta wrapper overlayed. if navigation disabled then dont chow cta wrapper. */}
            <div className="keen-slider__slide">
              <Image
                src={slideImages[slideImages.length - 1]}
                alt={`${alt} image ${slideImages.length}`}
                fill
                sizes="(max-width: 695px) 80vw, (max-width: 1024px) 45vw, (max-width: 1287px) 50vw, (max-width: 1820px) 20vw, (max-width: 3440px) 17.5vw, 15vw"
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                  setPlaceholderImage(e, slideImages.length - 1)
                }
              />
              {!disableNavigation && (
                <div className={styles.ctaWrapper}>
                  <div className={styles.cta}>
                    <p>Like this car?</p>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onViewFullDetails();
                      }}
                      styleOverride={styles.button}
                      colour="foreground"
                      style="solid"
                      tabIndex={-1}
                    >
                      View full details
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!showOnlyFirstImage && loaded && instanceRef.current && instanceRef.current.track.details?.slides && (
          <>
            <div
              className={cc({ [styles.navigation]: true, [styles.visible]: showDesktopNavigation })}
              data-hide-only="mobile"
            >
              <button
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                disabled={currentSlide === 0}
                className={cc({ [styles.arrow]: true, [styles.visible]: true })}
                aria-label="show previous slide"
                type="button"
              >
                <IconChevronLeft width={24} height={24} />
              </button>
              <button
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                className={cc({ [styles.arrow]: true, [styles.right]: true, [styles.visible]: true })}
                aria-label="show next slide"
                type="button"
              >
                <IconChevronLeft width={24} height={24} />
              </button>
            </div>
            <div className={styles.progressDots}>
              <CarouselDots currentSlide={currentSlide} instanceRef={instanceRef} visibleAfterActive={4} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VCCarousel;
export const MemoizedVCCarousel = React.memo(VCCarousel);
