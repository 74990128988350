'use client';

import React, { useState } from 'react';
import cc from 'classcat';

import Button from '@/common/components/Button/index.tsx';
import { TooltipBoundary } from '@/common/components/Tooltip/index.tsx';
import { extractStockPhotos, getPrettyComments } from '@/common/lib/StockItemHelper.ts';
import { getPrettyPriceFormat, toTitleCase } from '@/common/lib/utils.ts';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import { MemoizedVCCarousel } from './VCCarousel/index.tsx';
import VCDealerComments from './VCDealerComments/index.tsx';
import VCLabel from './VCLabel/index.tsx';
import VCLocation from './VCLocation/index.tsx';
import VCSpecs from './VCSpecs/index.tsx';
import VCTooltip from './VCTooltip/index.tsx';

import styles from './styles.module.scss';

interface VehicleCardProps {
  vehicle: Stock;
  labelIcon?: React.FC<React.SVGAttributes<SVGAElement>>;
  labelText?: string;
  // defaults
  /** display CTA button; default false */
  showCTAButton?: boolean;
  /** display dealer comments, if enabled via CMS & available; default true */
  showDealerComments?: boolean;
  /** preload first image; default false */
  preload?: boolean;
  /** disable user navigation to stock page; default false */
  disableNavigation?: boolean;
  /** show only the first image and remove pagination; default false */
  showOnlyFirstImage?: boolean;
  /** Show location of stock */
  showLocation?: boolean;
  /** display vehicle specs; default true */
  showSpecs?: boolean;
  /** set card height to 100% to fit a container; default false */
  fitContainer?: boolean;
  /** display the weekly price of finance; default true */
  showWeeklyPrice?: boolean;
  /** display vehicle stock number; default false */
  showStockNumber?: boolean;
}

const VehicleCard = ({
  vehicle,
  labelIcon,
  labelText,
  showCTAButton = false,
  showDealerComments = true,
  preload = false,
  disableNavigation = false,
  showOnlyFirstImage = false,
  showLocation = true,
  showSpecs = true,
  fitContainer = false,
  showWeeklyPrice = true,
  showStockNumber = false,
}: VehicleCardProps) => {
  const [userHovering, setUserHovering] = useState(false);

  const vehicleURL = StockSearch.getVehicleUrl(vehicle);
  const navigateToURL = () => window.location.assign(vehicleURL);

  return (
    <TooltipBoundary
      overhang={8}
      className={cc({ [styles.vehicleCard]: true, [styles.fitContainer]: fitContainer })}
      onMouseEnter={() => setUserHovering(true)}
      onMouseLeave={() => setUserHovering(false)}
      onFocus={() => setUserHovering(true)}
      onBlur={() => setUserHovering(false)}
      onKeyDown={(e) => {
        if (disableNavigation || e.currentTarget !== e.target) return;
        return e.key === 'Enter' && navigateToURL();
      }}
      role={disableNavigation ? undefined : 'button'}
      tabIndex={0}
      data-testid="vehicleCard"
    >
      <div className={styles.carousel}>
        <MemoizedVCCarousel
          preload={preload}
          showDesktopNavigation={userHovering}
          onViewFullDetails={navigateToURL}
          images={extractStockPhotos(vehicle).slice(0, 4)}
          alt={`${toTitleCase(vehicle.make)} ${toTitleCase(vehicle.model)}`}
          disableNavigation={disableNavigation}
          showOnlyFirstImage={showOnlyFirstImage}
          onNavigate={navigateToURL}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.details}>
          {!disableNavigation && (
            <a tabIndex={-1} className={styles.link} href={vehicleURL}>
              <span />
            </a>
          )}
          <div className={styles.heading}>
            <div className={styles.title}>
              {toTitleCase(vehicle.make)} <span className={styles.model}>{toTitleCase(vehicle.model)}</span>
            </div>
          </div>
          <div className={styles.series}>{vehicle.variant}</div>
          {showSpecs && (
            <VCSpecs
              vehicle={vehicle}
              separator="•"
              specsToDisplay={['listingType', 'year', 'transmission', 'mileage']}
            />
          )}
          {showDealerComments && <VCDealerComments comments={getPrettyComments(vehicle)} />}
          {!!labelText && <VCLabel Icon={labelIcon} text={labelText} />}
          {showLocation && vehicle.dealership?.address.suburb && (
            <VCLocation suburb={vehicle.dealership?.address.suburb} />
          )}
          {showWeeklyPrice && (
            <div className={styles.repaymentInformation}>
              <p>
                <strong>{!!vehicle.weeklyPrice ? getPrettyPriceFormat(vehicle.weeklyPrice) : '$?'}/wk</strong>{' '}
                <span>with a novated lease</span>
              </p>
              <div className={styles.tooltip}>
                <VCTooltip>
                  <div dangerouslySetInnerHTML={{ __html: VehicleCard.tooltip }} />
                </VCTooltip>
              </div>
            </div>
          )}
          {showCTAButton && (
            <div className={styles.cta}>
              <Button color="action-transparent" style="outline" styleOverride={styles.button} href={vehicleURL}>
                View this car
              </Button>
            </div>
          )}
          {showStockNumber && <span className={styles.stockNumber}>Stock ID: {vehicle.stockNo}</span>}
        </div>
      </div>
    </TooltipBoundary>
  );
};

VehicleCard.tooltip = `This calculator is provided for illustrative purposes only. The figures are estimates and do not constitute a quote or offer. The figures are based on the information you provided and a number of assumptions.`;

export default VehicleCard;
