import { camelCase, isArray, isObject, transform } from 'lodash-es';

export const replaceStringByIndex = (source: string[], replace: string, idx: number) => {
  if (idx < 0 || source.length < idx + 1) {
    return source;
  }

  const newArray = [...source.slice(0, idx), replace, ...source.slice(idx + 1, source.length)];
  return newArray;
};

export const toTitleCase = (str: string | undefined) => {
  if (!str) return;

  // Handle unqiue cases of Make/Model with switch
  switch (str.toLowerCase()) {
    case 'mercedes-benz':
      return 'Mercedes Benz';
    case 'mercedes-amg':
      return 'Mercedes AMG';
    case 'harley-davidson':
      return 'Harley Davidson';
    case 'suv':
    case 'bmw':
    case 'mg':
    case 'fpv':
    case 'hsv':
    case 'ldv':
    case 'mdc':
      return str.toUpperCase();
    default:
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }
};

export const getPrettyPriceFormat = (price: Maybe<number> | undefined, showDollar = true) => {
  if (!price || price <= 0) {
    return showDollar ? '$0' : '0';
  }

  const roundedPrice = Math.round(price);
  return (
    (!!showDollar ? '$' : '') +
    (roundedPrice.toString().slice().length === 11
      ? Math.abs(Number(roundedPrice) / 1.0e6)
          .toFixed(2)
          .toLocaleString() + 'M'
      : roundedPrice.toLocaleString())
  );
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

export const makeMap = (make: string) => {
  switch (make?.toUpperCase()) {
    case 'FORD PERFORMANCE VEHICLES':
      return 'FPV';
    case 'HOLDEN SPECIAL VEHICLES':
      return 'HSV';
    default:
      return make;
  }
};

export function capitalizeName(name: string | undefined) {
  if (!name) {
    return '';
  }

  return name
    .toLowerCase()
    .replace('-region', '')
    .replace('-suburb', '')
    .replace(/-/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function camelize(obj: Record<string, any>) {
  return transform(
    obj,
    (acc, value, key, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      acc[camelKey] = isObject(value) ? camelize(value) : value;
    },
    {} as Record<string, any>,
  );
}
